import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/layout/Layout';
import customerData from '../data/customer-data';

import style from '../css/index.css'

import globe from '../assets/images/globe-solid.svg';
import handshake from '../assets/images/handshake-solid.svg';
import check from '../assets/images/check-solid.svg';


const Index = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return (
  <Layout>
    <section id="stats" className="py-20">
      <div className='container mx-auto '>
        <div className='text text-4xl font-semibold ml-4'>Proizvodi</div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(product => (
            <div key={product.id} className="flex-1 px-3">
              <CustomerCard product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
    
    {/*
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    */}
    <section className="container mx-auto md:my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Kvaliteta koja vrijedi.</h3>
      <p className="mt-8 text-xl font-light">
        Ukližimo u bolje sutra.
      </p>
      <p className="mt-8">
        
        <a className="px-4" href="/kontakt">
          <Button size="xl" border>Kontaktirajte nas</Button>
        </a>
      </p>
    </section>
  </Layout>)
}

export default Index;
